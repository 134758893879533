import { combineReducers } from "redux";
import { changeClusterNameReducer} from "./reducers/changeClusterNameReducer";
import { changeStateNameReducer} from "./reducers/changeStateNameReducer";
import { changeDistrictNameReducer} from "./reducers/changeDistrictNameReducer";
import { changeWardNameReducer} from "./reducers/changeWardNameReducer";
import { changeIUNameReducer } from "./reducers/changeIUNameReducer";


const rootReducer = combineReducers({
  clusterName: changeClusterNameReducer,
  stateName: changeStateNameReducer,
  districtName: changeDistrictNameReducer,
  wardName: changeWardNameReducer,
  iuName: changeIUNameReducer

});

export default rootReducer;
