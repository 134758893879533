export function changeClusterName(data) {
  return {
    type: "CHANGE_CLUSTER_NAME",
    payload: { clusterName: data },
  };
}

export function changeIUName(data) {
  return {
    type: "CHANGE_IU_NAME",
    payload: { iuName: data },
  };
}

export function changeWardName(data) {
  return {
    type: "CHANGE_WARD_NAME",
    payload: { wardName: data },
  };
}

export function changeDistrictName(data) {
  return {
    type: "CHANGE_DISTRICT_NAME",
    payload: { districtName: data },
  };
}
export function changeStateName(data) {
  return {
    type: "CHANGE_STATE_NAME",
    payload: { stateName: data },
  };
}


